#left__ui {
  background: radial-gradient(
    92.19% 89.74% at 32.27% 91.27%,
    rgba(31, 169, 17, 0.81) 17.21%,
    #2f15d0 64.58%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: #000;
  padding-left: 60px;
  padding-right: 60px;
}

section {
  display: flex;
  padding: 40px 0;
  gap: 16px;
  justify-content: flex-start;
  gap: 11px;
}

section:nth-of-type(2) {
  /* Putting the image on left of second section */
  flex-direction: row-reverse;
}

.left__ui_section-heading {
  border-bottom: 1px solid #e5e5e680;
  padding-bottom: 20px;
  text-align: right;
  color: #f2f2f2;
  text-align: right;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 30px */
}

.second-section {
  display: flex;
  justify-content: space-between;
}

.left__ui_cohort-wrapper img {
  /* Add the following styles to make the image cover its parent */
  width: 100%;
  height: 100%;
  transform: scale(1.35);
}


.left__ui_testimonial-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  /* Overriding the parent's padding to utilize full width when scrolled (horizontally) */
  margin-left: -60px;
  margin-right: -60px;
  /* Setting initial padding to 60px to match the layout */
  padding-left: 60px;
  padding-right: 60px;
}

.left__ui_testimonial-wrapper img {
  align-self: end;
}

/* Hiding the scrollbar on testimonials */
.left__ui_testimonial-wrapper::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


@media only screen and (max-width: 1023px){
  section {
    flex-direction: column;
    padding: 20px 0;
  }

  section:nth-of-type(2) {
    flex-direction: column;

  }

  section:nth-of-type(2) img {
    padding-top: 20px;
  }
}