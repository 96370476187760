#right__ui {
  background-color: #fff;
  box-shadow: 0px 14px 64px -4px #18274b1f;
  box-shadow: 0px 8px 22px -6px #18274b1f;
  height: 100vh;
  padding-left: 60px;
  padding-right: 60px;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
}

.right__ui-container {
  width: 365px;
  display: flex;
  flex-direction: column;
}

.right__ui-container h3 {
  color: #b0b1b3;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 46.8px */
}

.right__ui-container input {
  display: flex;
  width: calc(340px - 48px);
  padding: 20px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e5e5e6;
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  margin-top: 32px;
  margin-bottom: 24px;
}

.right__ui-container button {
  display: flex;
  width: 340px;
  height: 59px;
  padding: 18px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #19191a;
  background: #19191a;
  color: #fff;
  cursor: pointer;
}

.right__ui-container p {
  color: #19191a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  margin-top: 42px;
}


@media only screen and (max-width: 1023px) {
  #right__ui {
    position: unset;
  }
}