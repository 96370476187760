.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto; /* Center the container */
  display: grid;
  grid-template-columns: 51.52% 48.48%;
}

.container > div {
  box-sizing: border-box;
}

@media only screen and (max-width: 1023px) {
  .container {
      display: flex;
      flex-direction: column;
  }
}