@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', sans-serif;
  color: #fff;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
