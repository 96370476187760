.text__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 322px;
}

.text__block.right-align {
  text-align: right !important;
}

.text__block h4 {
  color: #f2f2f2;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 37.2px */
}

.text__block p {
  color: #f2f2f2;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}

@media only screen and (max-width: 1023px) {
  .text__block.right-align {
    all: unset !important;
  }
}
