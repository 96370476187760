.testimonial {
  display: flex;
  min-width: 356px;
  padding: 20px;
  max-height: 136px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.04);
}

.testimonial__user-details {
  color: #19191a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
}

.testimonial__user-designation {
  color: #96979a;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
}

.testimonial__text {
  color: #1d2129;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}
