.scroller {
    max-width: 100%;
    /* Overriding the parent's padding of 60px */
    margin-right: -60px;
  }
  
  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
  }
  
  .scroller {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }
  
  .scroller .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
  }
  
  .scroller {
    --_animation-direction: forwards;
    --_animation-duration: 20s;
  }
  
  .scroller[data-speed="slow"] {
    --_animation-duration: 60s;
  }
  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }
  
  
  :root {
    --clr-primary-400: hsl(215, 25%, 27%);
    --clr-primary-900: hsl(218, 33%, 9%);
  }
  
  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }
  
  .tag-list li {
    padding: 1rem;
    background: var(--clr-primary-400);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
  }
  